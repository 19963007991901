import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { Button, Badge, Collapse } from "react-bootstrap";
import { db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import AppContext from "./AppContext";
import Pagination from "./Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPrint } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faEye, faPrint);

const ExamList = ({ refreshKey }) => {
  const examsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastExam = currentPage * examsPerPage;
  const indexOfFirstExam = indexOfLastExam - examsPerPage;
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [exams, setExams] = useState([]);
  const currentExams = exams.slice(indexOfFirstExam, indexOfLastExam);
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [expandedExams, setExpandedExams] = useState({});
  const { userName } = useContext(AppContext);

  const fetchExams = () => {
    const q = query(collection(db, "quizCode"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const examsData = snapshot.docs.map((doc) => {
        const exam = {
          id: doc.id,
          name: doc.id,
          qualification: doc.data().Qualification,
          profession: doc.data().Profession,
          year: doc.data().Year,
          session: doc.data().Session,
          autors: doc.data().Autors || [],
          description: doc.data().Description,
        };

        // Definiowanie collectionName w kontekście każdego egzaminu
        exam.collectionName = `${exam.qualification
          .toLowerCase()
          .replace(".", "")}${exam.year}${exam.session}`;

        return exam;
      });

      const filteredExams = examsData.filter((exam) =>
        exam.autors.includes(userName)
      );
      setExams(filteredExams);
    });

    return unsubscribe; // Zwracamy funkcję do wyłączenia nasłuchiwania
  };

  useEffect(() => {
    const unsubscribe = fetchExams(); // Rozpocznij nasłuchiwanie zmian
    return () => unsubscribe(); // Wyłącz nasłuchiwanie po odmontowaniu komponentu
  }, [refreshKey]);

  const handleSort = (field) => {
    let direction = "asc";
    if (sortField === field && sortDirection === "asc") {
      direction = "desc";
    }
    setSortField(field);
    setSortDirection(direction);

    let sortedExams = [...exams];
    sortedExams.sort((a, b) => {
      if (a[field] < b[field]) return direction === "asc" ? -1 : 1;
      if (a[field] > b[field]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setExams(sortedExams);
  };

  const handleExamClick = (examId) => {
    setExpandedExams((prev) => ({
      ...prev,
      [examId]: !prev[examId],
    }));
  };

  const formatQualification = (qualification) => {
    // Konwertuje wszystkie litery na wielkie i dodaje "-" przed pierwszą cyfrą
    return qualification
      .toUpperCase() // Zamienia wszystkie litery na wielkie
      .replace(/(\D+)(\d+)/, "$1-$2"); // Dodaje "-" przed cyframi
  };

  const handlePreviewClick = async (collectionName, qualification) => {
    // Otwórz nowe okno dla podglądu
    const previewWindow = window.open("", "_blank", "width=1000,height=800");

    try {
      // Pobierz pytania z kolekcji o nazwie exam.collectionName
      const questionsRef = collection(db, collectionName); // "collectionName" to nazwa kolekcji pytań
      const querySnapshot = await getDocs(questionsRef);

      // Pobieramy wszystkie pytania i ich pola (a, b, c, d, question, answer)
      const questions = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => parseInt(a.id) - parseInt(b.id)); // Sortowanie pytań po ID

      // Określenie koloru w zależności od liczby pytań
      const questionCount = questions.length;
      const countColor = questionCount < 40 ? "red" : "green";

      // Tworzenie struktury HTML w nowym oknie, które odzwierciedla layout z obrazka
      previewWindow.document.write(`
            <html>
                <head>
                    <title>Podgląd - ${collectionName}</title>
                    <style>
                        body { font-family: Arial, sans-serif; margin: 20px; }
                        table { width: 100%; border-spacing: 20px; }
                        td { vertical-align: top; }
                        .content { 
                            width: 65%; /* Szerokość kolumny pytań */
                            word-wrap: break-word; /* Zawijanie tekstu */
                        }
                        .widget { 
                            width: 30%; /* Stała szerokość widgetu */
                            background: #f5f5f5; 
                            padding: 20px;
                            max-width: 300px; 
                            overflow: hidden; /* Ukrywa nadmiar treści */
                        }
                        .task { 
                            margin-bottom: 15px; 
                            padding: 10px; 
                            border-bottom: 1px solid #ddd; 
                            word-wrap: break-word;
                        }
                        .footer { 
                            background: #f5f5f5; 
                            padding: 10px; 
                            text-align: center; 
                        }
                        .btn-end { 
                            background-color: #e74c3c; 
                            color: white; 
                            padding: 10px 20px; 
                            border: none; 
                            cursor: pointer; 
                        }
                        .btn-end:hover { 
                            background-color: #c0392b; 
                        }
                        input { 
                            width: 100%; 
                            margin-bottom: 10px; 
                            padding: 5px; 
                        }
                        .question-text { 
                            word-wrap: break-word; 
                            max-width: 100%; 
                        }
                        .correct-answer {
                            color: green;
                            font-weight: bold;
                        }
                    </style>
                </head>
                <body>
                    <div class="header">
                        <h1>Autonomiczny System Egzaminacyjny - Podgląd ${collectionName}</h1>
                    </div>
                    <table>
                        <tr>
                            <td class="content">
                                <h2>Egzamin Lista Zadań</h2>
                                ${questions
                                  .map((q, index) => {
                                    // Sprawdzamy, która odpowiedź jest poprawna na podstawie pola "answer"
                                    const correctAnswer = q.answer;
                                    const getAnswerClass = (option) =>
                                      option === correctAnswer
                                        ? "correct-answer"
                                        : "";

                                    return `<div class="task">
                                                <p class="question-text"><strong>Zadanie ${
                                                  index + 1
                                                }:</strong> ${q.question}</p>
                                                <ul>
                                                    <li class="${getAnswerClass(
                                                      "a"
                                                    )}">A: ${q.a}</li>
                                                    <li class="${getAnswerClass(
                                                      "b"
                                                    )}">B: ${q.b}</li>
                                                    <li class="${getAnswerClass(
                                                      "c"
                                                    )}">C: ${q.c}</li>
                                                    <li class="${getAnswerClass(
                                                      "d"
                                                    )}">D: ${q.d}</li>
                                                </ul>
                                            </div>`;
                                  })
                                  .join("")}
                            </td>
                            <td class="widget">
                                <p>Kwalifikacja</p>
                                <input type="text" value="${formatQualification(
                                  qualification
                                )}" disabled />

                                <p>Czas rozpoczęcia egzaminu (symulacja)</p>
                                <input type="text" value="${new Date().toLocaleString()}" disabled />

                                <p>Czas zakończenia egzaminu (symulacja)</p>
                                <input type="text" value="${new Date(
                                  Date.now() + 60 * 60 * 1000
                                ).toLocaleString()}" disabled />

                                <p>Liczba pytań w bazie</p>
                                <input type="text" value="${questionCount}" style="color: ${countColor};" disabled />

                                <p>Oczekiwana liczba pytań</p>
                                <input type="text" value="40" disabled />

                                <p>Domyślny czas końca egzaminu:</p>
                                <input type="text" value="60:00" disabled />

                                <button class="btn-end" onclick="window.close()">Zamknij podgląd</button>
                            </td>
                        </tr>
                    </table>
                    <div class="footer">
                        <small>System próbnych egzaminów zawodowych</small>
                    </div>
                </body>
            </html>
        `);
    } catch (error) {
      console.error("Błąd podczas pobierania danych egzaminu: ", error);
    }
  };

  const handlePrintExam = async (collectionName, qualification) => {
    try {
      const questionsRef = collection(db, collectionName);
      const querySnapshot = await getDocs(questionsRef);

      const questions = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => parseInt(a.id) - parseInt(b.id));

      const printContent = `
      <html>
      <head>
        <title>Wydruk egzaminu - ${collectionName}</title>
        <style>
          body { font-family: Arial, sans-serif; margin: 20px; }
          table { width: 100%; border-collapse: collapse; }
          th, td { border: 1px solid black; padding: 8px; text-align: left; }
          img { max-width: 200px; height: auto; margin-top: 10px; }
          .correct-answer { color: green; font-weight: bold; }
          .italic-text { font-style: italic; color: #555; }
        </style>
      </head>
      <body>
        <h1>Wydruk egzaminu - ${collectionName}</h1>
        <h2>Kwalifikacja: ${formatQualification(qualification)}</h2>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Pytanie</th>
              <th>Odpowiedzi</th>
            </tr>
          </thead>
          <tbody>
            ${questions
              .map(
                (q, index) => `
                <tr>
                  <td>${index + 1}</td>
                  <td>
                    ${q.question}
                    ${
                      q.image
                        ? `<br/><img src="${q.image}" alt="Ilustracja do pytania" onerror="this.src='placeholder-image-url.jpg';">`
                        : ""
                    }
                    ${
                      q.video
                        ? `<br/><span class="italic-text">Pytanie zawiera materiał multimedialny</span>`
                        : ""
                    }
                  </td>
                  <td>
                    <ul>
                      <li class="${
                        q.answer === "a" ? "correct-answer" : ""
                      }">A: ${q.a}</li>
                      <li class="${
                        q.answer === "b" ? "correct-answer" : ""
                      }">B: ${q.b}</li>
                      <li class="${
                        q.answer === "c" ? "correct-answer" : ""
                      }">C: ${q.c}</li>
                      <li class="${
                        q.answer === "d" ? "correct-answer" : ""
                      }">D: ${q.d}</li>
                    </ul>
                  </td>
                </tr>
              `
              )
              .join("")}
          </tbody>
        </table>
      </body>
      </html>
    `;

      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.top = "-10000px";
      document.body.appendChild(iframe);

      const iframeDoc = iframe.contentWindow || iframe.contentDocument;
      iframeDoc.document.open();
      iframeDoc.document.write(printContent);
      iframeDoc.document.close();

      iframeDoc.focus();
      iframeDoc.print();

      iframeDoc.onafterprint = () => document.body.removeChild(iframe);
    } catch (error) {
      console.error("Błąd podczas drukowania egzaminu:", error);
    }
  };

  return (
    <div className="mt-4">
      <div className="row">
        <div className="col-1"></div>
        <div className="alert alert-info col-4 align-middle" role="alert">
          Tym kolorem oznaczone są arkusze Twojego autorstwa
        </div>
        <div className="col-1"></div>
        <div className="alert alert-secondary col-4 align-middle" role="alert">
          Tym kolorem oznaczone są arkusze, do których masz dostęp
        </div>
        <div className="col-1"></div>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th onClick={() => handleSort("id")}>lp</th>
            <th onClick={() => handleSort("name")}>Kod egzaminu</th>
            <th onClick={() => handleSort("qualification")}>Kwalifikacja</th>
            <th onClick={() => handleSort("qualification")}>
              Kod arkusza w bazie
            </th>
            <th onClick={() => handleSort("profession")}>Zawód</th>
            <th>Osoby z prawem edycji</th>
            <th>Opis</th>
            <th>
              {" "}
              <div style={{ textAlign: "center" }}>
                <FontAwesomeIcon icon="fa-solid fa-eye" /> /{" "}
                <FontAwesomeIcon icon="fa-solid fa-print" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {currentExams.map((exam, index) => {
            // Trim and convert to strings for a strict comparison
            const authorName = exam.autors[0]
              ? exam.autors[0].toString().trim()
              : "";
            const trimmedUserName = userName.toString().trim();
            const isFirstAuthor = authorName === trimmedUserName;

            return (
              <React.Fragment key={exam.id}>
                <tr
                  className={`align-middle ${
                    isFirstAuthor ? "table-info" : ""
                  } ${!isFirstAuthor ? "table-muted" : ""}`}>
                  <td>{index + 1}</td>
                  <td onClick={() => handleExamClick(exam.id)}>{exam.name}</td>
                  <td>{exam.qualification}</td>
                  <td className="text-muted">
                    <strong>{exam.collectionName}</strong>
                  </td>
                  <td>{exam.profession}</td>
                  <td>
                    {exam.autors.map((author, idx) => (
                      <Badge key={idx} pill bg="primary" className="me-2">
                        {author}
                      </Badge>
                    ))}
                  </td>
                  <td>{exam.description === "" ? "-" : exam.description}</td>
                  <td>
                    <button
                      className="btn btn-outline-success me-2"
                      onClick={() =>
                        handlePreviewClick(
                          exam.collectionName,
                          exam.qualification
                        )
                      }>
                      <FontAwesomeIcon icon="fa-solid fa-eye" />
                    </button>
                    <button
                      className="btn btn-light mt-2"
                      onClick={() =>
                        handlePrintExam(exam.collectionName, exam.qualification)
                      }>
                      <FontAwesomeIcon icon="fa-solid fa-print" />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <Collapse in={expandedExams[exam.id]}>
                      <div className="p-3 mb-2 bg-light text-dark rounded text-muted">
                        <p>Rok utworzenia: {exam.year}</p>
                        <p>Unikalny kod sesji: {exam.session}</p>
                        <p>Skrót kwalifikacji: {exam.qualification}</p>
                        <p>
                          Autor arkusza: <strong>{authorName}</strong>
                        </p>
                      </div>
                    </Collapse>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <Pagination
        usersPerPage={examsPerPage}
        totalUsers={exams.length}
        paginate={paginate}
      />
    </div>
  );
};

export default ExamList;
